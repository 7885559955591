$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cookie-message
    // *
    // *
    var cookieMessage = Cookies.get('cookie-message');

    $(".js-cookie-message-btn").click(function () {
        $(this).parents(".js-cookie-message").fadeOut();
        Cookies.set('cookie-message', '1', { expires: 365 });
    });

    if (!cookieMessage) {
        $(".js-cookie-message").show();
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements() {
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-heroslider");

    if ($heroSlider.length) {

        var $heroSliders = {};

        $heroSlider.each(function () {

            var id = $(this).attr('id');

            $heroSliders[id] = $(this).slick({
                infinite: true,
                fade: true,
                dots: true,
                arrows: false,
                autoplay: $(this).data("autoplay"),
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                responsive: []
            });
        });

        $(".js-heroslider-image").click(function () {
            var id = $(this).parents(".js-heroslider").attr('id');
            $heroSliders[id].slick("slickNext");
        });

    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderTeaser
    // *
    // *
    var $sliderTeaser = $(".js-sliderteaser");

    if ($sliderTeaser.length) {

        var $sliders = {};

        $sliderTeaser.each(function () {

            var id = $(this).attr('id');

            $sliders[id] = $(this).slick({
                infinite: true,
                fade: true,
                dots: true,
                arrows: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                responsive: []
            });
        });

        $(".js-sliderteaser-image").click(function () {
            var id = $(this).parents(".js-sliderteaser").attr('id');
            $sliders[id].slick("slickNext");
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * marquee
    // *
    // *

    var $marqueeItems = $(".js-marquee-item");
    for (i = 0; i < 100; i++) {
        $marqueeItems.clone().appendTo(".js-marquee");
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMobile
    // *
    // *
    $(".js-nav-button").click(function () {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $(".js-header").toggleClass("mobileNavActive");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * descSlider
    // *
    // *
    var $descSlider = $(".js-descslider");

    if ($descSlider.length) {
        $descSlider.each(function () {
            var $descSliderImages = $(this).find(".js-descslider-images"),
                $descSliderContent = $(this).find(".js-descslider-content");

            $descSliderImages.slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).find(".js-descslider-nav-prev"),
                nextArrow: $(this).find(".js-descslider-nav-next"),
                asNavFor: $descSliderContent,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            infinite: true,
                            dots: false,
                            arrows: false,
                            variableWidth: true
                        }
                    }
                ]
            });

            $descSliderContent.slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: false,
                autoplay: false,
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                asNavFor: $descSliderImages,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            variableWidth: true
                        }
                    }
                ]
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function () {
            var $sliderImages = $(this).find(".js-slider-images"),
                $sliderHeader = $(this).find(".js-slider-header"),
                $sliderContent = $(this).find(".js-slider-content"),
                $asNavFor = $(this).find(".js-slider-content, .js-slider-header");

            $sliderImages.slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                autoplay: false,
                speed: 1000,
                prevArrow: $(this).find(".js-slider-nav-prev"),
                nextArrow: $(this).find(".js-slider-nav-next"),
                autoplaySpeed: 7000,
                waitForAnimate: true,
                asNavFor: $asNavFor,
                responsive: []
            });

            $sliderHeader.slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: false,
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                responsive: []
            });

            $sliderContent.slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: false,
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                responsive: []
            });

        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * bookingButton: add Class to header if window scrolls up or down
    // *
    // *
    var position = $(window).scrollTop(),
        $bookingButton = $(".js-bookingbutton");

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > position) {
            //scroll down
            $bookingButton.addClass("scroll-down").removeClass("scroll-up");
            setTimeout(function () {
                $bookingButton.addClass("scroll-up").removeClass("scroll-down");
            }, 2000);
        } else {
            // scroll up
            $bookingButton.addClass("scroll-up").removeClass("scroll-down");
        }

        position = scroll;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * event form
    // *
    // *
    $(".js-eventform-select").change(function () {
        var selectedOption = $(".js-eventform-select option:selected").val();
        $(".js-eventform-size").addClass("active");
        $(".js-eventform-size option:eq(0)").prop("selected", true);
        $(".js-eventform-size option").hide();
        $('.js-eventform-size option[data-option="' + selectedOption + '"]').show();
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobile video
    // *
    // *
    $(window).on('load resize', function () {
        if (window.innerWidth < 1080) {
            var video = $(".js-video-switcher");
            var mobileSrc = $(".js-video-switcher").data("mobile");
            if (video.length > 0) {
                video.attr('src', mobileSrc);
                video[0].load();
            }
        }
    });

    $(".js-video-volume-controls").click(function(){
        $(this).toggleClass("active");
        var video = $(this).parents(".js-video-wrapper").find("video");
        var muted = video.prop("muted");
        video.prop("muted",!muted);
    })
});
